import { Tooltip } from 'bootstrap'
import { languages } from './languages.js'
import JSZip from 'jszip'
import { getPoetry, getPythonReadme, pythonCodePostprocessing } from './python_code_post_processing.js'
import moment from 'moment'
import { backendDomain, frontendDomain, protocol } from './constants.js'
import { getCargo, getRustReadme } from './rust_code_post_processing.js'

export function setCookie (cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export function getCookie (cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function redirectRegisteredUser () {
  const token = getCookie('token')
  if (token !== '') {
    window.location.href = '/'
  }
}

export function validateEmail (mail) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return (true)
  }
  return (false)
}

export function addPages (paginationList, page, totalPages, paginationElementFunction) {
  const startPage = Math.max(page - 2, 1)
  const endPage = Math.min(page + 3, totalPages + 1)
  let previous
  if (page === 1) {
    previous = paginationElementFunction(true, 'Previous', page - 1)
  } else {
    previous = paginationElementFunction(false, 'Previous', page - 1)
  }
  paginationList.appendChild(previous)

  for (let i = startPage; i < endPage; i++) {
    const pageElement = paginationElementFunction(false, i, i)
    if (page === i) {
      pageElement.className = pageElement.className + ' active'
    }
    paginationList.appendChild(pageElement)
  }
  let next
  if (totalPages + 1 > page + 1) {
    next = paginationElementFunction(false, 'Next', page + 1)
  } else {
    next = paginationElementFunction(true, 'Next', page + 1)
  }
  paginationList.appendChild(next)
}

export function addClickTooltips () {
  const tooltips = document.querySelectorAll('span[data-bs-toggle="tooltip"]')
  for (const tooltip of tooltips) {
    const toolTipInit = new Tooltip(tooltip, { html: true })
    toolTipInit.trigger = 'hover click'
  }
}

export function setMenu () {
  const token = getCookie('token')
  const menu = document.getElementById('menu')
  if (token !== '') {
    menu.insertAdjacentHTML(
      'afterbegin', '<div class="row">\n' +
      '    <div id="curl2url-link" class="col-3 col-sm-6 col-md-6 col-lg-8">\n' +
      '      <h3 class="text-muted .d-none d-none d-sm-block"><a href="/curlconverter/">Curl2Url</a></h3>\n' +
      '      <h5 class="text-muted .d-none d-block d-sm-none"><a href="/curlconverter/">Curl2Url</a></h5>\n' +
      '    </div>\n' +
      '    <div class="col-3 col-sm-2 col-md-2 col-lg-1">\n' +
      '      <h3 class="text-muted .d-none d-none d-sm-block"><a href="/pricing/">Pricing</a></h3>\n' +
      '      <h5 class="text-muted .d-none d-block d-sm-none"><a href="/pricing/">Pricing</a></h5>\n' +
      '    </div>\n' +
      '    <div class="col-3 col-sm-2 col-md-2 col-lg-2  text-sm-left text-md-center">\n' +
      '      <h3 class="text-muted text-nowrap d-none d-sm-block"><a href="/my-urls/">My urls</a></h3>\n' +
      '      <h5 class="text-muted text-nowrap d-block d-sm-none"><a href="/my-urls/">My urls</a></h5>\n' +
      '    </div>\n' +
      '    <div class="col-3 col-sm-2 col-md-1 col-lg-1">\n' +
      '      <h3 class="text-muted text-nowrap d-none d-sm-block"><a href="/user/">User</a></h3>\n' +
      '      <h5 class="text-muted text-nowrap d-block d-sm-none"><a href="/user/">User</a></h5>\n' +
      '    </div>\n' +
      '  </div>'
    )
  } else {
    menu.insertAdjacentHTML(
      'afterbegin', '<div class="row">\n' +
      '    <div id="curl2url-link" class="col-3 col-sm-6 col-md-6 col-lg-8">\n' +
      '      <h3 class="text-muted .d-none d-none d-sm-block"><a href="/curlconverter/">Curl2Url</a></h3>\n' +
      '      <h5 class="text-muted .d-none d-block d-sm-none"><a href="/curlconverter/">Curl2Url</a></h5>\n' +
      '    </div>\n' +
      '    <div class="col-3 col-sm-2 col-md-2 col-lg-1">\n' +
      '      <h3 class="text-muted .d-none d-none d-sm-block"><a href="/pricing/">Pricing</a></h3>\n' +
      '      <h5 class="text-muted .d-none d-block d-sm-none"><a href="/pricing/">Pricing</a></h5>\n' +
      '    </div>\n' +
      '    <div class="col-3 col-sm-2 col-md-2 col-lg-2  text-sm-left text-md-center">\n' +
      '      <h3 class="text-muted text-nowrap d-none d-sm-block"><a href="/signup/">Sign Up</a></h3>\n' +
      '      <h5 class="text-muted text-nowrap d-block d-sm-none"><a href="/signup/">Sign Up</a></h5>\n' +
      '    </div>\n' +
      '    <div class="col-3 col-sm-2 col-md-1 col-lg-1">\n' +
      '      <h3 class="text-muted text-nowrap d-none d-sm-block"><a href="/login/">Log In</a></h3>\n' +
      '      <h5 class="text-muted text-nowrap d-block d-sm-none"><a href="/login/">Log In</a></h5>\n' +
      '    </div>\n' +
      '  </div>'
    )
  }
}

export function setFooter () {
  const menu = document.getElementById('footer')
  if (menu !== null) {
    menu.insertAdjacentHTML(
      'afterbegin', '    ' +
      '<div id="footer-contents">' +
      ' <div class="row justify-content-center">\n' +
      '  <div id="curl2url-link" class="col-12 col-sm-1">\n' +
      '   <a href="https://twitter.com/curl2url" aria-label="Learn more about Curl2Url in X">' +
      '    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" id="Capa_1" viewBox="0 0 455.731 455.731" xml:space="preserve">\n' +
      '     <g>\n' +
      '      <rect x="0" y="0" style="fill:#50ABF1;" width="455.731" height="455.731"/>\n' +
      '      <path style="fill:#FFFFFF;" d="M60.377,337.822c30.33,19.236,66.308,30.368,104.875,30.368c108.349,0,196.18-87.841,196.18-196.18   c0-2.705-0.057-5.39-0.161-8.067c3.919-3.084,28.157-22.511,34.098-35c0,0-19.683,8.18-38.947,10.107   c-0.038,0-0.085,0.009-0.123,0.009c0,0,0.038-0.019,0.104-0.066c1.775-1.186,26.591-18.079,29.951-38.207   c0,0-13.922,7.431-33.415,13.932c-3.227,1.072-6.605,2.126-10.088,3.103c-12.565-13.41-30.425-21.78-50.25-21.78   c-38.027,0-68.841,30.805-68.841,68.803c0,5.362,0.617,10.581,1.784,15.592c-5.314-0.218-86.237-4.755-141.289-71.423   c0,0-32.902,44.917,19.607,91.105c0,0-15.962-0.636-29.733-8.864c0,0-5.058,54.416,54.407,68.329c0,0-11.701,4.432-30.368,1.272   c0,0,10.439,43.968,63.271,48.077c0,0-41.777,37.74-101.081,28.885L60.377,337.822z"/>\n' +
      '     </g>\n' +
      '    </svg>\n' +
      '   </a>' +
      '  </div>\n' +
      '  <div id="curl2url-link" class="col-12 col-sm-1">\n' +
      '   <a href="https://www.youtube.com/@Curl2Url" aria-label="Learn more about Curl2Url in Youtube">' +
      '     <svg style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" version="1.1" viewBox="0 0 512 512" width="40" height="40"  xml:space="preserve" xmlns="http://www.w3.org/2000/svg"  ><g><path d="M501.299,132.766c-5.888,-22.03 -23.234,-39.377 -45.264,-45.264c-39.932,-10.701 -200.037,-10.701 -200.037,-10.701c0,0 -160.105,0 -200.038,10.701c-22.025,5.887 -39.376,23.234 -45.264,45.264c-10.696,39.928 -10.696,123.236 -10.696,123.236c0,0 0,83.308 10.696,123.232c5.888,22.03 23.239,39.381 45.264,45.268c39.933,10.697 200.038,10.697 200.038,10.697c0,0 160.105,0 200.037,-10.697c22.03,-5.887 39.376,-23.238 45.264,-45.268c10.701,-39.924 10.701,-123.232 10.701,-123.232c0,0 0,-83.308 -10.701,-123.236Z" style="fill:#ed1f24;fill-rule:nonzero;"/><path d="M204.796,332.803l133.018,-76.801l-133.018,-76.801l0,153.602Z" style="fill:#fff;fill-rule:nonzero;"/></g></svg>' +
      '   </a>' +
      '  </div>\n' +
      ' </div>\n' +
      ' <div class="row justify-content-center py-3">\n' +
      '  <div class="col-12 col-sm-8 col-md-5 col-lg-5 py-4">\n' +
      '   <ul style="list-style: none">' +
      '    <li><a href="/">Home</a></li>' +
      '    <li><a href="/blog/">Blog</a></li>' +
      '    <li><a href="/terms-of-service/">Terms of Service</a></li>' +
      '    <li><a href="/privacy-policy/">Privacy Policy</a></li>' +
      '   </ul>' +
      '  </div>' +
      ' </div>\n' +
      '</div>'
    )
  }
}

export function requestToCurl (jsonResponse) {
  let curlText = `curl '${jsonResponse.rawUrl}'`
  if (jsonResponse.method === 'HEAD') {
    curlText += ' -I'
  } else {
    curlText += ` -X ${jsonResponse.method}`
  }

  for (const key in jsonResponse.headers) {
    curlText += ` -H '${key}: ${jsonResponse.headers[key]}'`
  }
  const requestArray = getRequestData(jsonResponse)
  const requestData = requestArray[0]
  const requestType = requestArray[1]
  if (requestData !== null) {
    if (requestType === 'raw') {
      curlText += ` --data-raw '${requestData}'`
    } else if (requestType === 'form') {
      curlText += ` --data-raw '${requestData}'`
    } else {
      curlText += ` --data '${requestData}'`
    }
  }
  curlText += ' --compressed'
  return curlText
}

export function getRequestData (jsonResponse) {
  const contentType = jsonResponse.headers['content-type']
  let data = null
  let dataType = null
  if (jsonResponse.data !== null) {
    if (contentType === undefined) {
      // const params = new URLSearchParams(jsonResponse.data)
      // htmlText.textContent = params.toString()
      data = jsonResponse.data
      dataType = 'raw'
    } else if (contentType.includes('x-www-form-urlencoded')) {
      // const params = new URLSearchParams(jsonResponse.data)
      data = jsonResponse.data
      dataType = 'form'
    } else if (contentType.includes('json')) {
      data = jsonResponse.data
      dataType = 'json'
    } else {
      data = jsonResponse.data
      dataType = 'else'
    }
  }
  return [data, dataType]
}

export function setCommonHtml () {
  setMenu()
  setFooter()
}

export function convertCurlToLanguage (curl, jsonResponseResponse, language) {
  let generatedCode
  let warnings = []
  const converter = languages[language.toLowerCase()].converter;
  [generatedCode, warnings] = converter(curl, warnings)
  if (generatedCode.endsWith('\n')) {
    generatedCode = generatedCode.slice(0, -1)
  }
  return postProcessGeneratedCode(generatedCode, jsonResponseResponse, language)
}

function postProcessGeneratedCode (generatedCode, jsonResponseResponse, language) {
  console.log(language)
  if (language === 'PYTHON') {
    generatedCode = pythonCodePostprocessing(generatedCode, jsonResponseResponse)
  }
  return generatedCode
}

export function getGeneralReadme (stringDate, language, extension) {
  return `
Curl2Url: ${language} request ${stringDate}
=======================================
This repository is created from Curl2Url, it contains ${language} code to run the request created.

# Project contents contents:
- main.${extension}: Contains ${language} code to run the requests, serialization not available yet.
`
}

export function downloadRequestSerialization (curl, jsonResponseResponse, language) {
  const generatedCode = convertCurlToLanguage(curl, jsonResponseResponse, language)
  const zip = new JSZip()
  const stringDate = moment().format('_yyyy_MM_DD_HH_mm')
  const stringDateReadme = moment().format('yyyy_MM_DD_HH_mm')
  // for (var i = 0; i < 5; i++) {
  //   var txt = 'hello'
  //   zip.file('file' + i + '.txt', txt)
  // }
  console.log('curl', curl)
  if (language === 'PYTHON') {
    zip.file('main.py', generatedCode)
    zip.file('pyproject.toml', getPoetry(stringDate))
    zip.file('README.md', getPythonReadme(stringDateReadme))
  } else if (language === 'JAVA') {
    zip.file('main.java', generatedCode)
    zip.file('README.md', getGeneralReadme(stringDateReadme, language, 'java'))
  } else if (language === 'KOTLIN') {
    zip.file('main.kt', generatedCode)
    zip.file('README.md', getGeneralReadme(stringDateReadme, language, 'kt'))
  } else if (language === 'C') {
    zip.file('main.c', generatedCode)
    zip.file('README.md', getGeneralReadme(stringDateReadme, language, 'c'))
  } else if (language === 'RUST') {
    zip.folder('src')
    zip.file('src/main.rs', generatedCode)
    zip.file('Cargo.toml', getCargo(stringDate))
    zip.file('README.md', getRustReadme(stringDateReadme))
  }
  zip.generateAsync({
    type: 'base64'
  }).then(function (content) {
    const link = document.createElement('a')
    link.href = 'data:application/zip;base64,' + content
    link.download = `curl2url${stringDate}_${language.toLowerCase()}.zip`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}

export async function getCurl2Url (curl2UrlId) {
  const token = getCookie('token')
  const bearerToken = `Bearer ${token}`
  const options = {
    method: 'GET',
    headers: new Headers({
      Origin: `${protocol}://${frontendDomain}`,
      'Accept-Encoding': 'gzip, deflate',
      'Accept-Language': 'en-US,en;q=0.8',
      'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      Referer: `${protocol}://${frontendDomain}`,
      'X-Requested-With': 'XMLHttpRequest',
      Connection: 'keep-alive',
      authorization: bearerToken
    }),
    mode: 'cors'
  }
  const url = `${protocol}://${backendDomain}/curl2url/${curl2UrlId}`
  return await fetch(url, options)
}

export function transformDropdownContent (textContent) {
  textContent = textContent.trim().toUpperCase()
    .replaceAll(' ', '_')
    .replaceAll('Ô', 'O')
    .replaceAll('\'', '_')
    .replaceAll('Å', 'A')
    .replaceAll('Ç', 'C')
    .replaceAll('-', '_')
    .replaceAll('É', 'E')
  return textContent
}

export function passwordRequirements (passwordField, errorsList) {
  if (!checkPassword(passwordField.value)) {
    const quickAnchor = {
      innerText: '- Password must contain  min 8 characters, a symbol, upper and lower case letters and number'
    }
    const error = oneLineTag('p', quickAnchor)
    errorsList.appendChild(error)
  }
}

function checkPassword (str) {
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
  return re.test(str)
}

export function oneLineTag (tag, options) {
  return Object.assign(document.createElement(tag), options)
}

export function deleteCookie (name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}
